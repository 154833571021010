<template>
  <flex-box>
    <view-item-setting>
      <template #operation>
        <en-button type="primary" @click="operation.add.click">添加供应商</en-button>
      </template>
      <flex-box>
        <template #default="{ height }">
          <flex-box>
            <table-dynamic
              code="CUSFD"
              :data="table.data"
              :loading="table.loading"
              :height="height - 55"
              pagination
              :paging="table.paging"
              :method="table.get"
              :config="table.config"
            >
              <template #INDEX="{ $index }">
                {{ $index + 1 }}
              </template>
              <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['CustomerDto'] }">
                <button-delete :method="table.operation.delete.click" :params="row">删除</button-delete>
              </template>
              <template #NAME="{ row }: { row: EnocloudCommonDefinitions['CustomerDto'] }">
                <en-button type="primary" link @click="table.operation.serialNo.click(row)">{{ row.name }}</en-button>
              </template>
            </table-dynamic>
          </flex-box>
        </template>
      </flex-box>
    </view-item-setting>
    <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑供应商' : '添加供应商'" direction="btt" size="80%">
      <en-form
        :model="detail.form.data"
        :rules="detail.form.rules"
        :loading="detail.form.loading"
        :ref="setRef('form')"
        class="grid grid-cols-5 gap-6"
      >
        <en-form-item label="供应商编号">
          <en-input v-model="detail.form.data.serialNo"></en-input>
        </en-form-item>
        <en-form-item label="供应商名称" prop="name">
          <select-maintain
            v-model="detail.form.data.name"
            :ajax="{
              action: 'GET /enocloud/common/customer',
              params: (params, value) => (params.payload = { quickSearch: value })
            }"
            :props="{ label: 'name', value: 'name' }"
            remote
            allow-create
            class="w-full"
          >
          </select-maintain>
        </en-form-item>
        <en-form-item label="联系方式">
          <en-input v-model="detail.form.data.cellphone"></en-input>
        </en-form-item>
        <!-- <en-form-item label="供应商类型" prop="type.code">
          <select-maintain
            v-model="detail.form.data.type"
            :ajax="{
              action: 'GET /enocloud/common/lookup/:lookupType',
              params: (params) => (params.paths = ['CUSTTYPE'])
            }"
            :props="{ label: 'message', value: 'code' }"

            remote
            class="w-full"
          ></select-maintain>
        </en-form-item> -->
        <en-form-item label="供应商助记符">
          <en-input v-model="detail.form.data.nameHint"></en-input>
        </en-form-item>
        <en-form-item label="状态">
          <select-maintain
            v-model="detail.form.data.status"
            :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CUSTSTAT']) }"
            :props="{ label: 'message', value: '' }"
            value-key="code"
            class="w-full"
          ></select-maintain>
        </en-form-item>
        <en-form-item label="联系人" prop="contactName">
          <en-input v-model="detail.form.data.contactName"></en-input>
        </en-form-item>
        <en-form-item label="联系人性别">
          <select-maintain
            v-model="detail.form.data.gender"
            :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['GENDER']) }"
            :props="{ label: 'message', value: '' }"
            value-key="code"
            class="w-full"
          ></select-maintain>
        </en-form-item>
        <en-form-item label="联系人生日" prop="dob">
          <en-date-picker v-model="detail.form.data.dob" class="w-full"></en-date-picker>
        </en-form-item>
        <en-form-item label="最大信用额度">
          <en-input-number v-model="detail.form.data.creditLine" :min="0" class="w-full"></en-input-number>
        </en-form-item>
        <en-form-item label="座机">
          <en-input v-model="detail.form.data.telephone"></en-input>
        </en-form-item>
        <en-form-item label="传真">
          <en-input v-model="detail.form.data.fax"></en-input>
        </en-form-item>
        <en-form-item label="邮箱">
          <en-input v-model="detail.form.data.email"></en-input>
        </en-form-item>

        <en-form-item label="所在地区" prop="area">
          <cascader-area v-model="detail.form.data.area" class="w-full"></cascader-area>
        </en-form-item>
        <en-form-item label="详细地址" class="col-span-2">
          <en-input v-model="detail.form.data.address" type="textarea"></en-input>
        </en-form-item>
        <en-form-item label="开户银行">
          <select-maintain
            v-model="detail.form.data.depositBank"
            :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['BANK']) }"
            :props="{ label: 'name', value: 'name' }"
            allow-create
            class="w-full"
          ></select-maintain>
        </en-form-item>
        <en-form-item label="开户账号">
          <en-input v-model="detail.form.data.depositAccount"></en-input>
        </en-form-item>
        <en-form-item label="税号">
          <en-input v-model="detail.form.data.taxNo"></en-input>
        </en-form-item>
        <en-form-item label="发票类型">
          <select-maintain
            v-model="detail.form.data.invoiceTypeName"
            :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['INVCTP']) }"
            :props="{ label: 'name', value: 'name' }"
            class="w-full"
          ></select-maintain>
        </en-form-item>
        <en-form-item label="法人">
          <en-input v-model="detail.form.data.legalRepresentative"></en-input>
        </en-form-item>
        <en-form-item label="经营范围">
          <en-input v-model="detail.form.data.scope"></en-input>
        </en-form-item>

        <en-form-item label="备注" class="col-span-2">
          <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
        </en-form-item>
        <en-form-item label="营业执照" class="col-start-1" prop="businessLicenseUrl">
          <upload-maintain v-model="detail.form.data.businessLicenseUrl" multiple dir="bussiness"></upload-maintain>
        </en-form-item>
      </en-form>

      <template #footer>
        <en-button @click="detail.footer.cancel.click">取消</en-button>
        <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
      </template>
    </en-drawer>
  </flex-box>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      form: {
        data: {
          cardNo: '',
          vehicleSpec: '',
          serviceAdvisor: '',
          dobStart: '',
          dobEnd: '',
          address: '',
          sortedBy: 'CREATED_DATE_desc',
  
       
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = Object.assign({type:'B'},this.form.data)
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/customer/:customerId'
          }
        },
        config: {
          NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'name'
              }
            }
          },
          CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },
          CONTACT_NAME: { header: { filter: { type: 'text', field: 'contactName' } } },
    
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['CustomerDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            serialNo: {
              async click(row: EnocloudCommonDefinitions['CustomerDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              fax: '',
              businessLicenseUrl: '',
              type:{code:'B'}

            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/customer/:customerId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/customer',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/customer',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入供应商名称' }],
              cellphone: [{ required: true, message: '请输入联系方式' }],
              'category.id': [{ required: true, message: '请输入供应商类型' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()

  }
})
</script>
